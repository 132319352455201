import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="flex flex-col justify-self-stretch p-4 m-auto h-full text-center">
      <h1 className="mt-10 font-bold">Tu nič nie je</h1>
      <p className="text-xl">
        Dostali ste sa na neznámu stránku, ktorá neexistuje. Skúste ísť späť,
        alebo využiť navigáciu v hornej časti.
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
